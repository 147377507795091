(function () {
  'use strict';

  angular
    .module('dashboard.index')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard.index', {
        url: '/index',
        templateUrl: 'dashboard/index/index.tpl.html',
        controller: 'DashboardIndexCtrl',
        controllerAs: 'index',
        roles: ['Learner', 'Teacher', 'admin', 'superadmin']
      });
  }
}());
